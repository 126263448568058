.score-input {
  display: inline-block;
  border: 1px solid #018dff;
  border-radius: 5px;
}

.score-input label {
  width: 70px;
  height: 35px;
  text-align: center;
  color: #018dff;
  line-height: 32px;
  font-weight: 700;
  font-size: 16px;
}

.score-input:first-of-type {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.score-input:nth-of-type(2) {
  border-radius: 0px;
}

.score-input:last-of-type {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.score-input input {
  display: none;
}

.score-input input:checked + label {
  background-color: #018dff;
  color: white;
}

.score-btn {
  width: 70px;
  height: 35px;
}

.history-sub-label {
  width: 50px;
  margin-right: 20px;
  text-align: center;
}

.history-session-val {
  width: 25px;
  text-align: center;
  font-weight: 600;
}

.history-score-val {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 1px;
}

.history-score-val span {
  font-weight: 600;
}

.history-level-val {
  width: 25px;
  text-align: center;
  font-weight: 600;
}
