#header {
  height: 64px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0 16px;
}

#logo{
  height: 40px;
}

#search-box {
  width: 350px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 25px;
}

#user-profile-box {
  width: 200px;
  height: 44px;
  background: #eff8ff;
  border-radius: 6px;
  margin-left: 7px;
  padding: 7px;
}

#map-marker-icon {
  pointer-events: none;
  margin-right: 8px;
}
