#side-nav-bar {
  min-width: 275px;
  position: sticky;
  left: 0;
  border-right: 1px solid #ecf0f3;
  background: white;
  padding: 16px;
}

#logo {
  height: 40px;
  margin: 0 auto 20px auto;
  display: block;
}

.nav-item {
  padding: 14px 18px;
  color: #000000;
  cursor: pointer;
  transition: 250ms;
}

.nav-item:hover {
  background: #eef3f873;
}

.nav-item div div img {
  height: 18px;
  width: 18px;
  vertical-align: text-bottom;
}

.nav-item div div span {
  margin-left: 10px;
  font-size: 15px;
  letter-spacing: 0px;
}

.active-nav-item {
  background: #eef3f8;
  border-radius: 6px;
  color: #018dff !important;
}

.active-nav-item:hover {
  background: #eef3f8;
}

.down-arrow-icon {
  width: 12px;
  height: 7px;
}

.nav-sub-item {
  padding: 7px 8px;
  width: 180px;
  margin: 3px 0 0 25px;
  color: #000000;
  transition: 250ms;
}

.nav-sub-item:hover {
  background: #ffffff8a;
}

.active-nav-sub-item {
  background: #ffffff;
  border-radius: 6px;
  color: #018dff !important;
}

.active-nav-sub-item:hover {
  background: #ffffff;
}
