.form-container {
  padding: 30px 50px;
  width: 500px;
}

.password-input {
  height: 40px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 400px;
  }
}

@media only screen and (max-width: 425px) {
  .form-container {
    width: 300px;
  }
}
