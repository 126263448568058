#home-container {
  min-height: calc(100vh - 130px);
  padding: 20px;
}

#hospital-illustration-img {
  width: 480px;
}

@media only screen and (max-width: 768px) {
  #hospital-illustration-img {
    width: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #hospital-illustration-img {
    width: 250px;
  }
}
