.personal-dash-container,
.registration-forms-container {
  .pagination-sm {
    float: right;
  }
  position: relative;
  .card {
    background-color: white;
    margin-left: 0;
  }
  .booking-size {
    font-size: 10px;
    color: #8797ad !important;
  }
  .icon-button {
    float: right;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  .booking-color {
    color: black;
    font-weight: 400;
  }
  .border-color1 {
    border-left: 5px purple solid;
  }
  .border-color2 {
    border-left: 5px seagreen solid !important;
  }
  .button-radius {
    border-radius: 20px;
  }
  .card-table-width {
    width: 100%;
  }

  Table tBody tr td {
    font-size: 12px;
  }
}
.row-rev {
  flex-direction: row-reverse !important;
}
.header-gap {
  margin-right: 2rem;
  cursor: pointer;
}
.personal-container {
  padding: 0 6rem;
  padding-top: 2rem;
  min-height: 80vh;
}
@media screen and (max-width: 1000px) {
  .personal-container {
    padding: 0 2rem;
    padding-top: 2rem;
    min-height: 80vh;
  }
  .personal-dash-container .MuiGrid-item:nth-child(3),
  .personal-dash-container .MuiGrid-item:nth-child(4) {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.view-consults {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
  svg {
    cursor: pointer;
    color: green;
    zoom: 1.3;
  }
}
.no-top-margin {
  margin-top: 0 !important;
}
.round-check {
  display: inline-block;
  zoom: 2.2;
  width: 20%;
  color: green;
}
.con-summary {
  display: inline-block;
  h6 {
    color: brown;
  }
}
.disclaimer {
  font-size: 0.9rem;
  font-style: italic;
}

.round-dp {
  background-color: rgb(40, 115, 142);
  color: white;
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 52%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.right-align {
  float: right !important;
  color: white;
  height: 1.4rem;
  width: 6rem;
}
.red {
  background-color: rgb(186, 75, 27);
}
.green {
  background-color: green;
}
.yellow {
  background-color: rgb(201, 201, 60);
}
.auto-height {
  height: auto;
}
.purple-font-color {
  color: purple;
}
.blue-font-color {
  color: navy;
  font-size: 1.1rem;
  font-weight: 500;
}
.large-font {
  font-size: 1.4rem;
}
.medium-font {
  font-size: 1.1rem;
}
.margin-left-room {
  margin-left: -0.5rem;
}
.display-inline {
  display: inline;
}
.display-inline-block {
  display: inline-block;
}
.height-2-rem {
  height: 1.8rem !important;
}
.margin-above {
  margin-top: 2rem;
  padding-left: 0.6rem;
}
.green-font {
  color: green;
  font-weight: 500;
}
.doc-header {
  font-weight: bold;
  size: 2rem;
}
.back {
  color: black;
  background-color: #dedede;
  width: 7rem;
  border: 1px solid #bbbbbb;
  margin-bottom: 2rem;
  height: 2.4rem;
}
.full-width {
  width: 100% !important;
  background-color: rgb(228, 233, 247) !important;
}

.submit-btn {
  background-color: #502ecb;
  width: 5.3rem;
  color: white;
  border: 1px solid #acacfe;
  height: 2.4rem;
}
input[type="file"] {
  color: transparent;
}
input[type="file"] {
  display: none;
}
.fileUpload input.upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.margin-btm-1 {
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.margin-top-1 {
  margin-top: 0.4rem !important;
  padding-top: 0.2rem;
}
.no-decoration {
  text-decoration: none;
}
.large-x {
  width: 2.5rem !important;
}
.large-fonts {
  font-size: 1.1rem !important;
}
.flex {
  display: flex;
}
