#footer {
  height: 60px;
  border-top: 1px solid #dfdfdf;
  /* text-align: end;
  padding-right: 20px; */
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 60px;
  padding: 0 30px;
}

#copyright-text {
  color: #7b7b7b;
}
