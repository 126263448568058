.card {
  width:18rem;
  height:10rem;
  background-color:#eaeefc;
  margin:1.5rem;
  a {
    color:#8797AD !important;
    font-size: 18px;
    font-weight: bold;
  }
}

.card-link{
  cursor: pointer;
  text-decoration: none;
  margin-top: 12%;
}
span.Add {
  font-weight: 900;
  font-size: 32px;
}

.family-container {
  display: flex;
  flex-wrap: wrap;
}
.family-card {
  background-color: white !important;
}
.family-card-body {
  text-align: center;
  position: static !important;
  top: inherit;
  left: inherit;
  margin-top: 12%;
}
.add-family-span{
  margin-left: 9%;
  margin-top: 12%;
}
.card-text button {
  float: right !important;
}

.family-card-overlay-text{
  background-color: #EEEEEE;
  color: gray;
  position: absolute;
  z-index:100;
  transform: rotate(-45deg);
  top: 25px;
  padding: 2px 10px;
  left: -28px;
  width: 130px;
  text-align: center;
  line-height: inherit;
}