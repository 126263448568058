#patient-profile-img {
  width: 55px;
  height: 55px;
}

#patient-details-card {
  width: 220px;
}

#timeline-patient {
  margin: 0px;
}

#table-add-record-btn {
  margin-top: -45px;
  margin-bottom: 10px;
}
